import React from 'react'

import {useState, useEffect} from 'react'
import Header from '../../components/Header'

import { useNavigate, useParams } from 'react-router';
import { Button, Input} from 'rsuite';

import Config from '../../config/Config';
import Api from '../../services/Api';

import Loading from 'react-loading';
import { ToastContainer, toast } from "react-toastify";

function Account() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [data, setData]: any = useState({});

    useEffect(() => {
        
        const getAccountData = () => {

            const token:any = localStorage.getItem('token');

            Api.get('/auth/me',token).then((response) => {

                console.log(response);
                setData(response);
                setLoading(false);
            });
        }

        getAccountData();
    },[]);

    const saveAccount = () => {

        setLoading(true);

        const token:any = localStorage.getItem('token');
        Api.post('/data/me',{
            name:data.name,
            password:data.password
        },token).then((response: any) => {
            console.log(response);
            toast("Data berhasil disimpan", {
                type: "success",
            });

            setLoading(false);

        });
    }

    
    return ( 
        <div className='min-h-screen bg-slate-100'>

            <Header />

            <div className='px-16 pb-2 pt-16'>
                <h4 className='font-semibold my-4'>Account</h4>
                <div className='flex flex-row justify-between w-1/3  p-4 bg-gray-50 rounded-lg min-h-64'>

                        <form className='w-full' onSubmit={(e) => {
                            e.preventDefault();
                             saveAccount();

                        }} >
                        <div className='w-full'>
                            <p className='my-1'>Username</p>
                            <Input placeholder="Username"
                          
                            value={data.username} disabled/>
                            <p className='my-1'>Name</p>
                            <Input placeholder="Name"
                            onChange={(e: any) => {
                                setData({
                                    ...data,
                                    name: e
                                })
                            }}
                            value={data.name}/>
                            <p className='my-1'>Password</p>
                            <Input
                            onChange={(e: any) => {

                                setData({
                                    ...data,
                                    password: e
                                })

                            }}
                            placeholder="******" type='password' />
                            <span className='text-xs text-gray-400'>*Leave blank if you don't want to change password</span>

                            <div className='flex flex-row justify-end mt-4'>
                                {loading ? (
                                    <Loading type='spin' color='#000' height={20} width={20} />
                                ) : (
                                    <Button appearance='primary' color='green' type='submit'>Save</Button>
                                )  }
                            </div>
                        </div>
                        </form>

                </div>

            </div>

            <ToastContainer />

        </div>
     );
}

export default Account;