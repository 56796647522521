import React from 'react'

import {useState, useEffect} from 'react'


import Config from '../../config/Config';
import Api from '../../services/Api';

import Loading from 'react-loading';
import { useParams } from 'react-router';

function OrderTracking(props:any) {

    const { id } = props;
    const [loading, setLoading] = useState(true);
    const [data, setData]: any = useState({
      
    });

    useEffect(() => {
        const token:any = localStorage.getItem('token');

        Api.get('/data/order/' + id,token).then((response) => {

            console.log(response);
            setData(response);

            setLoading(false);
        });
    },[]);
    
    return ( 
        <div className=''>

            {!loading ? (

            <div className=''>
                <table className='w-full'>
                    <thead>
                        <tr className='border-b border-gray-100'>
                            <th className='text-left py-2'>Datetime</th>
                            <th className='text-left py-2'>Status</th>
                            <th className='text-left py-2'>Detail</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.tracking.map((item:any) => (
                            <tr className='border-b border-gray-100'>
                                <td className='py-2'>{item.datetime}</td>    
                                <td className='py-2'>{item.status}</td>    
                                <td className='py-2'>{item.detail}</td>    
                            </tr>
                        ))}
                    </tbody>

                </table>

                </div>

            ) : (
                <div className='flex flex-row justify-center items-center'>
                    <Loading type='spin' color='#000' height={50} width={50} />

                    </div>
            )}

        </div>
     );
}

export default OrderTracking;