import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './screens/Login/Login';
import Dashboard from './screens/Dashboard/Dashboard';
import Order from './screens/Order/Order';
import OrderNew from './screens/Order/OrderNew';
import OrderDetail from './screens/Order/OrderDetail';
import OrderPrint from './screens/Order/OrderPrint';
import Report from './screens/Report/Report';

import Branch from './screens/Branch/Branch';
import User from './screens/User/User';
import Setting from './screens/Setting/Setting';
import Account from './screens/Account/Account';

import 'rsuite/dist/rsuite.min.css';


function App() {
  return ( 

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/order" element={<Order />} />
        <Route path="/order/new" element={<OrderNew />} />
        <Route path="/order/detail/:id" element={<OrderDetail />} />
        <Route path="/order/print/:id" element={<OrderPrint />} />
        <Route path="/report" element={<Report />} />
        <Route path="/branch" element={<Branch />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/user" element={<User />} />
        <Route path="/account" element={<Account />} />
      </Routes>
    </BrowserRouter>
   );
}

export default App;