import React from 'react'
import {useState, useEffect} from 'react'
import Header from '../../components/Header';
import Input from 'rsuite/Input';

import Config from '../../config/Config';
import Api from '../../services/Api';
import { Button, SelectPicker, Modal } from 'rsuite';
import Loading from 'react-loading';
import { useNavigate } from 'react-router';
import {toast, ToastContainer} from 'react-toastify';

function OrderNew() {

    const token: any = localStorage.getItem('token');
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [country, setCountry]: any = useState([]);
    const [senderStates, setSenderStates]: any = useState([]);
    const [senderCities, setSenderCities]: any = useState([]);
    const [recipientStates, setRecipientStates]: any = useState([]);
    const [recipientCities, setRecipientCities]: any = useState([]);

    const [box, setBox]: any = useState([]);

    const [serviceCharge, setServiceCharge]: any = useState(0);
    const [additional, setAdditional]: any = useState(0);

    const [total, setTotal]: any = useState(0);

    const [order, setOrder]: any = useState({
        sender_name: '',
        sender_phone: '',
        sender_passport: '',
        sender_address: '',
        sender_country: '',
        sender_prov: '',
        receiver_name: '',
        receiver_phone: '',
        receiver_address: '',
        receiver_country: '',
        receiver_prov: '',
        receiver_kota: '',
        telp_alternatif: '',
        box_id: '',
        service_charge: 0,
        tambahan: 0,
    });

    const [senderCountryName, setSenderCountryName] = useState("-");
    const [senderStateName, setSenderStateName] = useState("-");
    const [recipientCountryName, setRecipientCountryName] = useState("-");
    const [recipientStateName, setRecipientStateName] = useState("-");
    const [recipientCityName, setRecipientCityName] = useState("-");

    const [rate, setRate] = useState(0);

    const [orderItem, setOrderItem]: any = useState([
        {
            item_name: '',
            item_qty: '',
            item_desc: '',
        }
    ]);

    const localUser = localStorage.getItem('user');
     const [user, setUser]: any = useState(
        localUser ? JSON.parse(localUser) : {}
    );

    useEffect(() => {

        const getCountry = async () => {
            const response = await Api.get("/data/country",token).then((response) => {
                const countrySelect = response.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                });
                
                setCountry(countrySelect);
            });
        }

        const getBox = async () => {
            const response = await Api.get("/data/box",token).then((response) => {

                const boxSelect = response.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                });

                setBox(boxSelect);
            });
        }

        getCountry();
        getBox();

    },[]);

    useEffect(() => {
        const getSenderStates = async () => {
            const response = await Api.get(`/data/state/${order.sender_country}`,token).then((response) => {
                const stateSelect = response.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.provinsi_id,
                    }
                });

                setSenderStates(stateSelect);
                setOrder({
                    ...order,
                    sender_prov: stateSelect[0].value,
                });

           
                setSenderStateName(stateSelect[0].label);
               

            });
        }

       
        order.sender_country != '' && getSenderStates();
    

    },[order.sender_country]);


    useEffect(() => {

        const getRecipientStates = async () => {
            const response = await Api.get(`/data/state/${order.receiver_country}`,token).then((response) => {
                const stateSelect = response.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.provinsi_id,
                    }
                });

                setRecipientStates(stateSelect);

                setOrder({
                    ...order,
                    receiver_prov: stateSelect[0].value,
                });

                setRecipientStateName(stateSelect[0].label);

            });
        }

      
        order.receiver_country != '' && getRecipientStates();
        

    },[order.receiver_country]);

    useEffect(() => {

        const getRecipientCities = async () => {
            const response = await Api.get(`/data/city/${order.receiver_prov}`,token).then((response) => {
                
                if(response.length == 0) {
                    setRecipientCities([]);
                    setOrder({
                        ...order,
                        receiver_kota: '',
                    });
                }
                else 
                {
                    const citySelect = response.map((item: any) => {

                        return {
                            label: item.name,
                            value: item.kota_id,
                        }
                    });

                    setRecipientCities(citySelect);

                    setOrder({
                        ...order,
                        receiver_kota: citySelect[0].value,
                    });

                    setRecipientCityName(citySelect[0].label);
                }
                
            });
            
        }

       order.receiver_prov != '' &&  getRecipientCities();

    },[order.receiver_prov]);


    useEffect(() => {
        const getRate = async () => {
            const response = await Api.post('/data/price/rate',{
                origin: order.sender_prov,
                dest: order.receiver_prov,
                box_id: order.box_id,
            },token).then((response) => {
                setRate(response);
            }).catch((error) => {
                setRate(0);

                
            });
        }

        order.sender_prov != '' && order.receiver_kota != '' && order.box_id != '' && getRate();

    },[order.sender_prov, order.receiver_kota, order.box_id]);
    

    const submitOrder = async () => {
        setIsSubmitting(true);
        const response = await Api.post('/data/order', {
            ...order,
            items: orderItem,
        },token).then((response) => {
            console.log(response);

            if(response)
            {
                navigate('/order/detail/' + response.id);

                setModalConfirm(false);
            }
            else
            {
                toast('Failed to submit order', {
                    type: 'error',
                });

                setModalConfirm(false);
            }

            setIsSubmitting(false);
        });

    }

    useEffect(() => {

        const total = rate + parseInt(order.service_charge) + parseInt(order.tambahan);

        setTotal(total);

    }
    ,[rate, serviceCharge, additional]);


    return ( 
        <div className='min-h-screen bg-slate-100'>
            
            <Header />

            <div className='px-16 pb-8 pt-16'>
                <h4 className='my-4'>New Order</h4>
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-row justify-start gap-4 text-xs'>

                     <div className='flex-1 p-4 bg-gray-50 rounded-lg flex flex-col gap-1'>
                        
                        <p className='font-semibold'>Sender Information</p>
                        <label>Name</label>
                        <Input  placeholder='Name'
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    sender_name: value,
                                });
                            }}
                        />
                        <label>Phone</label>
                        <Input  placeholder='Sender Phone' 
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    sender_phone: value,
                                });
                            }}

                        />
                        <label>Passport</label>
                        <Input  placeholder='Sender Passport'
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    sender_passport: value,
                                });
                            }}

                        />
                        <label>Address</label>
                        <Input  placeholder='Sender Address' 
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    sender_address: value, 
                                });
                            }}
                        />
                        <label>Country</label>
                        <SelectPicker 
                            data={country}
                            placeholder='Sender Country'
                          
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    sender_country: value,
                                });

                                const senderCountry = country.filter((item: any) => item.value == value);

                                setSenderCountryName(senderCountry[0].label);
                            }
                            }

                            value={order.sender_country}
                        />
                        <label>State</label>
                        <SelectPicker 
                            data={senderStates}
                            placeholder='Sender State'
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    sender_prov: value,
                                });

                                const senderProv = senderStates.filter((item: any) => item.value == value);

                                setSenderStateName(senderProv[0].label);
                            }
                            }
                            value={order.sender_prov}
                            />
                    
                    </div>
                     <div className='flex-1 p-4 bg-gray-50 rounded-lg flex flex-col gap-1'>
                        
                        <p className='font-semibold'>Recipient Information</p>
                        <label>Name</label>
                        <Input  placeholder='Name'
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    receiver_name: value,
                                })
                                
                            }}
                            
                        />
                        <label>Phone</label>
                        <Input  placeholder='Recipient Phone' 
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    receiver_phone: value,
                                });
                            }}
                        />
                        <label>Phone 2 (alternative)</label>
                        <Input  placeholder='Recipient Phone 2' 
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    telp_alternatif: value,
                                });
                            }}
                        />
                        <label>Address</label>
                        <Input  placeholder='Recipient Address' 
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    receiver_address: value,
                                });
                            }}
                        />
                        <label>Country</label>
                        <SelectPicker 
                            data={country}
                            placeholder='Recipient Country'
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    receiver_country: value,
                                });

                                const recipientCountry = country.filter((item: any) => item.value == value);
                                
                                setRecipientCountryName(recipientCountry[0].label);

                            }
                            }

                            value={order.receiver_country}
                        />
                         <label>State</label>
                        <SelectPicker
                            data={recipientStates}

                            placeholder='Recipient State'
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    receiver_prov: value,
                                });

                                const recipientProv = recipientStates.filter((item: any) => item.value == value);

                                setRecipientStateName(recipientProv[0].label);
                            }}

                            value={order.receiver_prov}
                            />
                         <label>City</label>
                        <SelectPicker 
                            data={recipientCities}
                            placeholder='Recipient City'

                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    receiver_kota: value,
                                });

                                const recipientCity = recipientCities.filter((item: any) => item.value == value);

                                setRecipientCityName(recipientCity[0].label);
                            }
                            }

                            value={order.receiver_kota}
                            />
                      
                    </div>
                     <div className='w-72 p-4 bg-gray-50 rounded-lg flex flex-col gap-1  bg-sky-100 text-sm'>
                             
                        <p className='font-semibold'>Box & Rate</p>
                        <label>Box Size</label>
                        
                        <SelectPicker
                            data={box}
                            placeholder='Box Size'
                            onChange={(value) => {
                                setOrder({
                                    ...order,
                                    box_id: value,
                                });

                             }
                            }

                            value={order.box_id}
                            />
                       
                        <div className='flex flex-col mt-2 gap-2'>

                               <div>
                                 <p className='font-semibold'>Origin</p>    
                                <p>{senderCountryName}, {senderStateName}</p>
                               </div>
                                <div>
                                <p className='font-semibold'>Destination</p>    
                                
                                <p>{recipientCountryName}, {recipientStateName}, {recipientCityName}</p>
                                </div>

                                <div>
                                    <p className='font-semibold'>Service charge</p>    
                                    <Input placeholder='0' 
                                            type='number'    
                                            onChange={(value) => {
                                                setOrder({
                                                    ...order,
                                                    service_charge: value,
                                                });

                                                setServiceCharge(value);
                                            }}
                                        />
                                </div>
                                <div>
                                    <p className='font-semibold'>Additional</p>    
                                     <Input  placeholder='0' 
                                            type='number'
                                            onChange={(value) => {
                                                setOrder({
                                                    ...order,
                                                    tambahan: value,
                                                });

                                                setAdditional(value);
                                            }}
                                        />
                                </div>

                                <div className='border-t border-gray-300 py-4 mt-4'>
                                    <p className='font-semibold'>Total Rate</p>
                                 <h3 className='font-bold text-lg text-sky-900'>RM {total}</h3>
                                </div>

                                <div>
                                   {rate > 0 ?  (
                                    <Button appearance='primary' color='green' size='lg'
                                   className='w-full'
                                   onClick={() => {

                                    setModalConfirm(true);

                                   }}
                                   >Submit Order</Button>
                                   ) : (
                                    <Button appearance='primary' color='green' size='lg'
                                      className='w-full'
                                        disabled
                                      >Submit Order</Button>
                                      )
                                   }
                                   
                                </div>
                            </div>
                       
                      
                    </div>
                </div>
                <div className='flex flex-row justify-start items-center gap-4 text-sm'>

                     <div className='flex-1 p-4 bg-gray-50 rounded-lg flex flex-col gap-1'>
                        
                        <h5>Item</h5>
                        
                       <table className='w-full'>
                            <thead>
                                <tr>
                                    <th className='text-left py-4 w-16'>No</th>
                                    <th className='text-left py-4'>Item name</th>
                                    <th className='text-left py-4'>Qty</th>
                                    <th className='text-left py-4'>Description</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {orderItem.map((item: any, index: number) => (
                                        <tr>
                                            <td className='py-2 pr-2'>
                                               {index + 1}
                                            </td>
                                            <td className='py-2 pr-2'>
                                                <Input placeholder='Item name'
                                                onChange={(value) => {
                                                    const newOrderItem = [...orderItem];
                                                    newOrderItem[index].item_name = value;
                                                    setOrderItem(newOrderItem);
                                                }}

                                                value={item.item_name}
                                                />
                                            </td>
                                            <td className='py-2 pr-2'>
                                                <Input
                                                type='number'
                                                placeholder='Qty'
                                                onChange={(value) => {
                                                    const newOrderItem = [...orderItem];
                                                    newOrderItem[index].item_qty = value;
                                                    setOrderItem(newOrderItem);
                                                }}
                                                
                                                value={item.item_qty}
                                                />
                                            </td>
                                            <td className='py-2 pr-2'>
                                                <Input placeholder='Description'
                                                onChange={(value) => {
                                                    const newOrderItem = [...orderItem];

                                                    newOrderItem[index].item_desc = value;
                                                    setOrderItem(newOrderItem);
                                                }}

                                                value={item.item_desc}
                                                
                                                />
                                            </td>
                                            <td className='w-32 py-2 pr-2'>
                                               {index != 0 && <Button appearance='ghost' color='red'
                                               onClick={() => {
                                                    const newOrderItem = [...orderItem];
                                                    newOrderItem.splice(index, 1);
                                                    setOrderItem(newOrderItem);
                                               }}
                                               >Remove</Button>}
                                            </td>
                                        </tr>
                                    ))}

                                    <tr>
                                        <td colSpan={4} className='py-4'>
                                            <Button appearance='ghost' color='green'
                                                onClick={() => {
                                                    setOrderItem([...orderItem, {
                                                        item_name: '',
                                                        item_qty: '',
                                                        item_desc: '',
                                                    }])
                                                }}
                                            >Add Item</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                                    
                     
                    </div>
                     <div className='w-72'>
                        
            
                    </div>
                   
                </div>
            </div>
                
            </div>

            {/* Delete Modal  */}
            <Modal open={modalConfirm} onClose={() => setModalConfirm(false)}
            size='xs'
            >
              <form  onSubmit={(e)=> {
                e?.preventDefault();
                setIsSubmitting(true);
                
              }}>
              <Modal.Header>
                <Modal.Title>Submit order?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className='my-2'>Are you sure to submit this order, make sure all are correctly fiiled?</p>
              </Modal.Body>
              <Modal.Footer>
                {isSubmitting ? (
                  <Loading type='spin' color='#000' height={20} width={20} />  
                ) : (
                  <>
                  <Button type="submit" appearance="primary" color='green'
                    onClick={() => {
                        submitOrder();
                    }}
                  >
                  Confirm Submit
                </Button>
                <Button onClick={()=> setModalConfirm(false)}  appearance="subtle">
                  Cancel
                </Button>
                  </>
                )}
              </Modal.Footer>
              </form>
            </Modal>

            <ToastContainer />
        </div>
     );
}

export default OrderNew;