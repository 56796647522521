import React from 'react'
import { useState, useEffect } from 'react'
import Logo from '../../assets/img/jpi-logo.png'
import Header from '../../components/Header';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { IconButton, ButtonToolbar, ButtonGroup, Button, Input, Modal } from 'rsuite';

import PlusIcon from '@rsuite/icons/Plus';

import OrderTracking from '../Order/OrderTracking';

import Config from '../../config/Config';
import Api from '../../services/Api';
import { ToastContainer, toast } from "react-toastify";

function Dashboard() {

    const navigate = useNavigate();
    
    const [modalTracking, setModalTracking] = useState(false);
    const [id, setId] = useState('');

    const [receiptNumber, setReceiptNumber] = useState('');

    const [total, setTotal] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalDelivered, setTotalDelivered] = useState(0);
    const [totalUndelivered, setTotalUndelivered] = useState(0);

    const thisMonth = new Date().getMonth() + 1;
    const thisYear = new Date().getFullYear();

    const [loading, setLoading] = useState(false);

    const getTracking = () => {

        setLoading(true);

        const token:any = localStorage.getItem('token');

        Api.get('/data/tracking/' + receiptNumber,token).then((response) => {

            if(response.id != undefined){
            console.log(response);
            setId(response.id);
            setModalTracking(true);
            setLoading(false);
            }else{

                toast("Receipt number not found", {
                    type: "error",
                  });
                  setLoading(false);
            }
        });
    }

    useEffect(() => {

        // get data dashboard
        const token:any = localStorage.getItem('token');

        Api.get('/data/dashboard',token).then((response) => {

            console.log(response);
            setTotal(response.total);
            setTotalAmount(response.total_amount);
            setTotalDelivered(response.total_delivered);
            setTotalUndelivered(response.total_undelivered);
        });
    },[]);

    return ( 
        <div className='min-h-screen bg-slate-100'>
            
            <Header />
            <div className='px-16 pb-2 pt-16'>
                <h4 className='font-semibold my-4'>Dashboard</h4>
                <div className='flex flex-row justify-between w-full items-center p-4 bg-gray-50 rounded-lg h-64'>

                   <div className='w-2/5 text-center'>
                    <h2 className='font-bold text-xl'> Welcome to JPI Partner Dashboard</h2>
                    <p className='text-sm'>Please select the menu on the left to start your journey</p>
                   </div>
                   <div className='w-3/5 text-center flex flex-row gap-8 justify-center'>
            
                    <div className='flex flex-row gap-2'>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            getTracking();
                        }}

                        className='flex flex-row gap-2'
                        >
                        <Input placeholder='Search no receipt' size='lg'
                        onChange={(value) => {
                            setReceiptNumber(value);
                        }}
                        value={receiptNumber}
                        />    
                         <Button appearance='primary' size='lg' className='w-72'
                        type='submit'
                         >Track order receipt</Button>
                        </form>
                    </div>
                     <ButtonToolbar>
                        <IconButton icon={<PlusIcon />} appearance='primary' size='lg' color='green'
                        onClick={() => {
                            navigate('/order/new');
                        }}
                        >New Order</IconButton>
                    </ButtonToolbar>
                   </div>
                            
                </div>
                <div className='flex flex-row justify-between w-full mt-6 gap-4'>
                            
                            <div className="w-1/2 bg-sky-200 p-12 text-sm text-sky-900 rounded-lg">
                                <h4 className='font-normal'>Total Monthly Sales Order ({thisMonth}/{thisYear})</h4>
                                <h3><span className='font-bold'>RM {totalAmount} </span>({total} orders)</h3>
                            </div>
                            <div className="w-1/4 bg-teal-200 p-12 text-sm text-teal-900 rounded-lg">
                               <h4 className='font-normal'>Delivered order</h4>
                                <h3 className='text-xl'>{totalDelivered} orders</h3>
                            </div>
                            <div className="w-1/4 bg-amber-200 p-12 text-sm text-amber-900 rounded-lg">
                               <h4 className='font-normal'>Undelivered order</h4>
                                <h3 className='text-xl'>{totalUndelivered} orders</h3>
                            </div>
                </div>
                <div className='p-4 text-lg'>
                    <p className='font-bold'>Note : </p>
                    <p>1. Pengirim wajib mencantumkan Photostate Pasport Indonesia<br/>2. Detail barang / packing list wajib di tulis</p>
                </div>
            </div>


            <Modal open={modalTracking} onClose={() => setModalTracking(false)}>
                <Modal.Header>
                    <Modal.Title>Track order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrderTracking id={id} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalTracking(false)} appearance="primary">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

           <ToastContainer />             

        </div>
     );
}

export default Dashboard;