import React from 'react'
import { useState, useEffect} from 'react'
import Logo from '../../assets/img/jpi-logo.png'
import { Button } from 'rsuite';

import { useNavigate } from "react-router-dom";

import Config from '../../config/Config';
import Api from '../../services/Api';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from 'react-loading';

function Login() {

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);

    const login = async () => {
       setLoading(true); 
      Api.post("/auth/login", {
        username: username,
        password: password,
      })
        .then((response) => {
          //console.log(response);
          if (response.access_token) {
            localStorage.setItem("token", response.access_token);
            navigate("/dashboard");
          }
          else
          {
            toast.error("Username atau Password salah!");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);

          toast.error("Username atau Password salah!");
          setLoading(false);
            
        });
    };
        
    return ( 
        <div
            className='flex flex-col justify-center items-center h-screen w-screen bg-gray-100'
        >
            <div className='flex flex-row justify-center items-center w-full'>

                <div className='w-5/6  md:w-1/2 lg:w-1/3 xl:w-1/4 bg-white rounded-lg shadow p-8'>
                    
                        <form className='flex flex-col gap-2'
                            onSubmit={(e) => {
                                e.preventDefault();
                                login();
                            }}
                        >
                            <img src={Logo} alt='JPI Logo' className='w-1/4 mx-auto mb-2' />
                            <h1 className='text-xl font-bold mb-8'>Login Partner</h1>
                            <input
                                onChange={(e) => setUsername(e.target.value)}
                                className='border border-gray-400 rounded-md px-4 py-2 mb-4'
                                type='text'
                                placeholder='Username'
                            />
                            <input
                                onChange={(e) => setPassword(e.target.value)}
                                className='border border-gray-400 rounded-md px-4 py-2 mb-4'
                                type='password'
                                placeholder='Password'
                            />


                            {loading ? (
                                <ReactLoading
                                    type={"spin"}
                                    color={"#000"}
                                    height={30}
                                    width={30}
                                />
                            ) : (
                            <Button
                                type='submit'
                                appearance='primary'
                                >Login</Button>
                            )}
                            
                        </form>
                    </div>

                </div>

                 <ToastContainer />
           
        </div>
     );
}

export default Login;