import React from 'react'

import XLSX from 'sheetjs-style'
import { Button } from 'rsuite';

const FileSaver = require('file-saver');

const ReportExcel = ({excelData, fileName} : any) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = (excelDataL: any, fileName: any) => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <Button
                onClick={(e) => exportToExcel(excelData,fileName)}
                appearance='primary'
                color='green'
                size='sm'
            >
                Export Excel
            </Button>
        </>
    )
}

export default ReportExcel;