import Config from "../config/Config";

const Api = {
  // Get using fetch

  get: async (url:any, token = null) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (token != null || token != undefined)
      myHeaders.append("Authorization", "Bearer " + token);

    //console.log("Get token", token);
    let options: any = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return fetch(Config.api_url + url, options)
      .then((response) => {
        if (response.status == 401) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        //console.log("error", error);
      });
  },
  // post using fetch
  post: async (url: any, data: any, token = null) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (token != null || token != undefined)
      myHeaders.append("Authorization", "Bearer " + token);

    //console.log("Post token", token);

    let options: any = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    };
    return fetch(Config.api_url + url, options)
      .then((response) => {
        if (response.status == 401 && url != "/auth/login") {
          localStorage.removeItem("token");
          window.location.href = "/login";
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  },
  // patch using fetch
  patch: async (url: any, data: any, token = null) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (token != null || token != undefined)
      myHeaders.append("Authorization", "Bearer " + token);

    let options: any = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(data),
    };
    return fetch(Config.api_url + url, options)
      .then((response) => {
        if (response.status == 401) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  },

  // delete using fetch
  delete: async (url: any, data = null, token = null) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (token != null || token != undefined)
      myHeaders.append("Authorization", "Bearer " + token);

    let options: any = {
      method: "DELETE",
      headers: myHeaders,
    };

    if (data != null) {
      options.body = JSON.stringify(data);
    }
    return fetch(Config.api_url + url, options)
      .then((response) => {
        if (response.status == 401) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  },

  sendPushNotification: async (data: any) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "key=" + Config.fcmKey);

    let options: any = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    };
    return fetch("https://fcm.googleapis.com/fcm/send", options)
      .then((response) => response.json())
      .catch((error) => {
        //console.log(error);
      });
  },
};

export default Api;
