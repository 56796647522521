import React from 'react'
import {useState, useEffect} from 'react'
import Logo from '../assets/img/jpi-logo.png'

import { Link } from 'react-router-dom';

import { Button } from 'rsuite';
import { Navbar, Nav } from 'rsuite';

import HomeIcon from '@rsuite/icons/legacy/Home';
import CogIcon from '@rsuite/icons/legacy/Cog';
import PowerOffIcon from '@rsuite/icons/legacy/PowerOff';
import UserIcon from '@rsuite/icons/legacy/User';
import { Table } from 'rsuite';

import { useNavigate } from 'react-router-dom';

import Config from '../config/Config';
import Api from '../services/Api';

function Header() {
    const navigate = useNavigate();

    const localUser = localStorage.getItem('user');

    const [user, setUser]: any = useState(
        localUser ? JSON.parse(localUser) : {}
    );

    useEffect(() => {
        const token:any = localStorage.getItem('token');

        Api.get('/auth/me',token).then((response) => {
            console.log(response);
            localStorage.setItem('user',JSON.stringify(response));
            setUser(response);
        });
    },[]);

    return ( 

          <div className='flex flex-row justify-between items-center w-full text-sky-700 bg-white border-b border-gray-200 fixed z-50'>
            <Navbar
                appearance='inverse'
                className='w-full'
                >
                    <Navbar.Brand href="#" className='font-bold hover:text-white'>JPI Partner Dashboard</Navbar.Brand>
                    <Nav>
                      
                        <Nav.Item
                        icon={<HomeIcon />}
                        onClick={() => {
                         navigate('/dashboard');
                        }}
                        >Dashboard</Nav.Item>
                        {/* <Nav.Menu title="About">
                            <Nav.Item eventKey="4">Branch</Nav.Item>
                            <Nav.Item eventKey="5">Account</Nav.Item>
                        </Nav.Menu> */}
                        <Nav.Item
                            onClick={() => navigate('/order')}
                        >Orders</Nav.Item>
                        <Nav.Item
                            onClick={() => navigate('/report')}
                        >Report</Nav.Item>

                        {user.level == 'Administrator' && (
                       <>
                        <Nav.Item
                        onClick={() => {
                            navigate('/branch');
                        }}
                        >Branch</Nav.Item>
                        <Nav.Item
                        onClick={() => {
                            navigate('/user');
                        }}
                        >User</Nav.Item>
                       </>
                        )}
                        
                    </Nav>
                    <Nav pullRight >

                        {user.level == 'Administrator' && (
                        <Nav.Item
                        onClick={() => {
                            navigate('/setting');
                        }}
                        icon={<CogIcon />}>Settings</Nav.Item>
                        )}
                        <Nav.Item icon={<UserIcon />}
                        onClick={() => {
                            navigate('/account');
                        }}    
                        >{user.name} ({user.level})</Nav.Item>
                        <Nav.Item
                        onClick={() => {
                            localStorage.removeItem('token');
                            localStorage.removeItem('user');
                            navigate('/login');
                        }}
                        icon={<PowerOffIcon color='yellow' />}>Logout</Nav.Item>
                    </Nav>
                </Navbar>
            </div>
     );
}

export default Header;