import React from 'react'
import {useState, useEffect, useRef} from 'react'
import Header from '../../components/Header'

import { useNavigate, useParams } from 'react-router';

import Config from '../../config/Config';
import Api from '../../services/Api';

import { Button, Modal } from 'rsuite';

import Loading from 'react-loading';

import OrderTracking from './OrderTracking';

function OrderDetail() {

    const navigate = useNavigate();
    const componentRef:any = useRef();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData]: any = useState({});

    const [modalTracking, setModalTracking] = useState(false);

    useEffect(() => {
        const token:any = localStorage.getItem('token');

        Api.get('/data/order/' + id,token).then((response) => {

            console.log(response);
            setData(response);

            setLoading(false);
        });
    },[]);

    return ( 
        <div className='min-h-screen bg-slate-100'>
            
            <Header />

            {!loading ? (
            <div className='px-16 pb-8 pt-16'>
                <div className='flex flex-row justify-between items-center'>
                     <h5 className='my-4'>Order Detail {data.no_resi} </h5>
                     <div className='flex flex-row gap-2'>
                        <Button appearance='ghost' 
                        onClick={() => {
                            navigate('/order/print/' + id);
                        }}
                        >Print Receipt</Button>
                       
                        <Button appearance='ghost' color='green'
                        onClick={() => {
                            setModalTracking(true);
                        }}
                        >Track order</Button>
                     </div>
                </div>
                <div className='flex flex-row gap-2 justify-center'>

                    <div className='w-1/3 bg-white rounded-lg shadow-sm p-8'>
                        <h5 className='mb-4 text-blue-500'>Sender</h5>      
                        <p className='font-bold'>Sender name</p>
                        <p>{data.sender_name}</p>

                        <p className='font-bold'>Sender phone</p>
                        <p>{data.sender_phone}</p>

                        <p className='font-bold'>Sender address</p>
                        <p>{data.sender_address}</p>

                        <p className='font-bold'>Sender country</p>
                        <p>{data.sender_country.name}</p>

                        <p className='font-bold'>Sender state</p>
                        <p>{data.sender_state.name}</p>
                    </div>
                    <div className='w-1/3 bg-white rounded-lg shadow-sm p-8'>

                         <h5 className='mb-4  text-blue-500'>Recipient</h5>   

                        <p className='font-bold'>Recipient name</p>
                        <p>{data.receiver_name}</p>

                        <p className='font-bold'>Recipient phone</p>
                        <p>{data.receiver_phone}</p>

                        <p className='font-bold'>Recipient address</p>
                        <p>{data.receiver_address}</p>

                        <p className='font-bold'>Recipient country</p>
                        <p>{data.receiver_country.name}</p>

                        <p className='font-bold'>Recipient state</p>
                        <p>{data.receiver_state.name}</p>

                        <p className='font-bold'>Recipient city</p>
                        <p>{data.receiver_city.name}</p>


                    </div>
                     <div className='w-1/2 bg-white rounded-lg shadow-sm p-8'>
                        <h5 className='mb-4  text-blue-500'>Status</h5>   

                        <p className='mb-8'>{data.status}</p>

                        <h5 className='mb-4  text-blue-500'>Items detail</h5>   

                         <table className='w-full'>

                            <thead>
                                <tr>
                                    <th className='border border-gray-200 p-2 text-left'>Item name</th>
                                    <th className='border border-gray-200 p-2 text-left'>Qty</th>
                                    <th className='border border-gray-200 p-2 text-left'>Desc</th>
                                </tr>
                            </thead>
                            <tbody className='text-xs'>
                                {data.items && data.items.map((item:any) => (
                                    <tr>
                                        <td className='border border-gray-200 p-2'>{item.nama_barang}</td>
                                        <td className='border border-gray-200 p-2'>{item.qty}</td>
                                        <td className='border border-gray-200 p-2'>{item.keterangan}</td>
                                    </tr>
                                ))}
                            </tbody>
                            </table>
                     </div>
                </div>
            </div>
            ) : (
                <div 
                className='flex justify-center items-center h-screen'
                >
                    <Loading type='spin' color='#000' height={50} width={50} />
                </div>
            )    
            }

            <Modal open={modalTracking} onClose={() => setModalTracking(false)}>
                <Modal.Header>
                    <Modal.Title>Track order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrderTracking id={id} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalTracking(false)} appearance="primary">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
     );
}

export default OrderDetail;