import React from 'react'
import { useState, useEffect} from 'react'
import Header from '../../components/Header';

import Config from '../../config/Config';
import Api from '../../services/Api';

import { ToastContainer, toast } from "react-toastify";

import TableGrid from '../../components/TableGrid';
import { Button, Input, Modal, Placeholder } from 'rsuite';
import Loading from 'react-loading';

function Branch() {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [lastRefresh, setLastRefresh] = useState(new Date());

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [name, setName] = useState("");

  const [editData, setEditData]: any = useState({});
  const [deletedId, setDeletedId] = useState("");

  const token:any = localStorage.getItem("token");

  const columns = [
    { label: "ID", field: "id" },
    { label: "Name", field: "name" },
  ];

  const deleteData = (id: string) => {
    // delete from API
    Api.delete(Config.api_url + "/data/branch/" + id).then((response) => {
      console.log(response);
      toast("Data berhasil dihapus", {
        type: "success",
      });

      setLastRefresh(new Date());
    });
  };

  const showEditData = (id: string) => {
    // get data from API
    Api.get("/data/branch/" + id,token).then((response) => {
      console.log(response);
      setEditData(response);
      setName(response.name);
      setModalEdit(true);
    });
  };

  const showConfirmDelete = (id: string) => {
    setDeletedId(id);
    setModalDelete(true);
    console.log(id);
  };

  const actions = [
    {
      label: "Edit",
      method: {
        type: "function",
        function: showEditData,
      },
    },
    {
      label: "Delete",
      method: {
        type: "function",
        function: showConfirmDelete,
      },
    },
  ];


  useEffect(() => {
    setLoading(true);

    // get data brand
    const getData = async () => {
        await Api.get(
            "/data/branch?limit=" +
            limit +
            "&page=" +
            page +
            "&filter=" +
            filter,
            token
            
        )
        .then((response) => {
            setData(response);
            console.log("Data", response);

            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    getData();

  }, [page, filter, lastRefresh]);

  const submitAdd = () => {
    // submit add data

    Api.post("/data/branch", {
      name: name,
    }, token)
      .then((response) => {

        if(!response)
        {
          console.log(response);
          toast.error("Failed to add item");
          setIsSubmitting(false);
          return;
        }
      
        setModalAdd(false);
        setName("");
        setIsSubmitting(false);
        setLastRefresh(new Date());
        toast.success("Item added successfully")
       
      }) 
      
      .catch((error) => {
        console.log(error);
      });
  };

  const submitEdit = () => {
    // submit add data

    Api.patch("/data/branch/"+editData.id, {
      name: name,
    }, token)
      .then((response) => {

        if(!response)
        {
          console.log(response);
          toast.error("Failed to update item");
          setIsSubmitting(false);
          return;
        }
      
        setModalEdit(false);
        setName("");
        setIsSubmitting(false);
        setLastRefresh(new Date());
        toast.success("Item updated successfully")
       
      }) 
      
      .catch((error) => {
        console.log(error);
      });
  };

  const submitDelete = () => {
    // delete from API
    Api.delete("/data/branch/" + deletedId,null, token).then((response) => {
      console.log(response);
      toast.success("Item deleted successfully");
      setModalDelete(false);
      setLastRefresh(new Date());
    });
  };

    return ( 
        <div className='min-h-screen bg-slate-100'>

            <Header />

            <div className='px-16 pb-2 pt-16'>
              <div className="flex justify-between gap-4 items-center mb-4">
              <div>
                <h5 className="py-2 font-bold">Branch</h5>
              </div>
              

               <div className='flex flex-row gap-2'>
                <div>
                <Input 
                  placeholder="Search"
                   onChange={(e: any) => setFilter(e)}
                    value={filter}
                   />
                </div>
                <div>
                  <span className="float-right">
                    <Button appearance='primary' color='green'
                    onClick={() => setModalAdd(true)}
                    >
                      Add Branch
                    </Button>
                  </span>
                </div>
                </div> 

            </div>

            <div className=''> 
             {!loading && (   
              <>
                 <TableGrid columns={columns} data={data} actions={actions} />
                 <div className="mt-4 flex justify-end">
                  <button
                    className="bg-gray-400 text-sm text-white px-3 py-1 rounded-md"
                    onClick={() => {
                      if (page > 1) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    Previous
                  </button>
                  <span className="px-3 py-1">{page}</span>
                  <button
                    className="bg-gray-400 text-sm text-white px-3 py-1 rounded-md"
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    Next
                  </button>
                </div>
                </>
             )}
             </div>   
            </div>

             {/* Add Branch   */}
            <Modal open={modalAdd} onClose={() => setModalAdd(false)} size="xs">
              <form  onSubmit={(e)=> {
                e?.preventDefault();
                setIsSubmitting(true);
                submitAdd();
              }}>
              <Modal.Header>
                <Modal.Title>Add Branch</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className='my-2'>Name</p>
                <Input type='text' placeholder='branch name' 
                onChange={(e: any) => setName(e)}
                value={name}
                />

              </Modal.Body>
              <Modal.Footer>
                {isSubmitting ? (
                  <Loading type='spin' color='#000' height={20} width={20} />  
                ) : (
                  <>
                  <Button type="submit" appearance="primary" color='green'>
                  Save
                </Button>
                <Button onClick={()=> setModalAdd(false)}  appearance="subtle">
                  Cancel
                </Button>
                  </>
                )}
              </Modal.Footer>
              </form>
            </Modal>


             {/* Edit Branch   */}
            <Modal open={modalEdit} onClose={() => setModalEdit(false)} size="xs">
              <form  onSubmit={(e)=> {
                e?.preventDefault();
                setIsSubmitting(true);
                submitEdit();
              }}>
              <Modal.Header>
                <Modal.Title>Edit Branch</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className='my-2'>Name</p>
                <Input type='text' placeholder='branch name' 
                onChange={(e: any) => setName(e)}
                value={name}
                />
              </Modal.Body>
              <Modal.Footer>
                {isSubmitting ? (
                  <Loading type='spin' color='#000' height={20} width={20} />  
                ) : (
                  <>
                  <Button type="submit" appearance="primary" color='green'>
                  Save
                </Button>
                <Button onClick={()=> setModalEdit(false)}  appearance="subtle">
                  Cancel
                </Button>
                  </>
                )}
              </Modal.Footer>
              </form>
            </Modal>

            {/* Delete Modal  */}
            <Modal open={modalDelete} onClose={() => setModalDelete(false)}
            size='xs'
            >
              <form  onSubmit={(e)=> {
                e?.preventDefault();
                setIsSubmitting(true);
                submitDelete();
              }}>
              <Modal.Header>
                <Modal.Title>Delete Branch</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className='my-2'>Are you sure to delete this data?</p>
              </Modal.Body>
              <Modal.Footer>
                {isSubmitting ? (
                  <Loading type='spin' color='#000' height={20} width={20} />  
                ) : (
                  <>
                  <Button type="submit" appearance="primary" color='red'>
                  Delete item
                </Button>
                <Button onClick={()=> setModalDelete(false)}  appearance="subtle">
                  Cancel
                </Button>
                  </>
                )}
              </Modal.Footer>
              </form>
            </Modal>

            <ToastContainer />
        </div>
     );
}

export default Branch;