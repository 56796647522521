import React from 'react'
import { useState, useEffect} from 'react'
import Header from '../../components/Header';

import Config from '../../config/Config';
import Api from '../../services/Api';

import { ToastContainer, toast } from "react-toastify";

import TableGrid from '../../components/TableGrid';
import { Button, Input, Modal, Placeholder } from 'rsuite';
import Loading from 'react-loading';
import { useNavigate } from 'react-router';

function Order() {

  const navigate = useNavigate();  

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [lastRefresh, setLastRefresh] = useState(new Date());


  const [editData, setEditData]: any = useState({});
  const [deletedId, setDeletedId] = useState("");

  const token:any = localStorage.getItem("token");

  const columns = [
    { label: "ID", field: "id" },
    { label: "Date", field: "sender_date" },
    { label: "Receipt number", field: "no_resi" },
    { label: "Sender", field: "sender_name" },
    { label: "Recipient", field: "receiver_name" },
    { label: "Box", field: "box_size" },
    { label: "Status", field: "status" },
    
  ];

  const deleteData = (id: string) => {
    // delete from API
    Api.delete(Config.api_url + "/data/order/" + id).then((response) => {
      console.log(response);
      toast("Data berhasil dihapus", {
        type: "success",
      });

      setLastRefresh(new Date());
    });
  };


  const actions = [
    {
      label: "Detail",
      method: {
        type: "url",
        url: "/order/detail",
      },
    },
  ];


  useEffect(() => {
    setLoading(true);

    // get data brand
    const getData = async () => {
        await Api.get(
            "/data/order?limit=" +
            limit +
            "&page=" +
            page +
            "&filter=" +
            filter,
            token
            
        )
        .then((response) => {
            setData(response);
            console.log("Data", response);

            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    getData();

  }, [page, filter, lastRefresh]);


    return ( 
        <div className='min-h-screen bg-slate-100'>

            <Header />

            <div className='px-16 pb-2 pt-16'>
              <div className="flex justify-between gap-4 items-center mb-4">
              <div>
                <h5 className="py-2 font-bold">Order</h5>
              </div>
              

               <div className='flex flex-row gap-2'>
                <div>
                <Input 
                  placeholder="Search"
                   onChange={(e: any) => setFilter(e)}
                    value={filter}
                   />
                </div>
                <div>
                  <span className="float-right">
                    <Button appearance='primary' color='green'
                    onClick={() => {
                         navigate("/order/new");
                    }}
                    >
                      Add Order
                    </Button>
                  </span>
                </div>
                </div> 

            </div>

            <div className=''> 
             {!loading && (   
              <>
                 <TableGrid columns={columns} data={data} actions={actions} />
                 <div className="mt-4 flex justify-end">
                  <button
                    className="bg-gray-400 text-sm text-white px-3 py-1 rounded-md"
                    onClick={() => {
                      if (page > 1) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    Previous
                  </button>
                  <span className="px-3 py-1">{page}</span>
                  <button
                    className="bg-gray-400 text-sm text-white px-3 py-1 rounded-md"
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    Next
                  </button>
                </div>
                </>
             )}
             </div>   
            </div>


            

            <ToastContainer />
        </div>
     );
}

export default Order;