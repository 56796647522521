import React from 'react'
import { useState, useEffect} from 'react'

import Config from '../../config/Config';
import Api from '../../services/Api';

import { useNavigate, useParams } from 'react-router';
import Barcode from 'react-barcode';

import Loading from 'react-loading';

const jpiLogo = require('../../assets/img/jpi-logo.png');

function OrderPrint(props:any, ref:any) {

    const navigate = useNavigate();
    const {id} = useParams();

    const localUser = localStorage.getItem('user');

    const [loading, setLoading] = useState(true);
    const [data, setData]: any = useState({});
    const [render, setRender] = useState(0);

    useEffect(() => {
        const token:any = localStorage.getItem('token');

        const getData =() => Api.get('/data/order/' + id,token).then((response) => {

            console.log(response);
            setData(response);
            setLoading(false);
           
            setTimeout(() => {
                window.print();
                navigate('/order/detail/' + id);
            }
            ,500);
        });

        getData();
       
    },[]);

    return ( 
        <div className='flex justify-center pt-4 text-xs'>
            {loading ? (
                
                <Loading type='spin' color='#000' height={20} width={20} />
            ) : (
                <table style={{width:600, borderWidth:1, textTransform: 'uppercase'}}>
                  <thead
                  
                  >
                    <tr>
                        <td colSpan={2}
                        width={300}
                        style={{
                            textAlign:'center',
                        }}
                        >
                            <h5
                            style={{alignItems:'center',justifyContent:'center',flexDirection:'row'}}
                            >
                                 <span
                                    style={{float:'left', padding:10}}
                                 >
                                       <img src={data.partner.logo} width={50} />
                                </span>
                                <span
                                 style={{float:'left',padding:10}}
                                >
                                       <img src={jpiLogo} width={50} />
                                </span>
                               
                               </h5>
                               <br/>
                               
                        </td>
                        <td
                         colSpan={2}
                         >
                            <Barcode value={data.no_resi} 
                            height={60}
                            />
                         </td>
                    </tr>
                    <tr>
                        <td
                        colSpan={4}
                        style={{
                            fontWeight:'bold',
                            fontSize:20,
                            padding:10,
                        }}
                        >
                            <h5>
                                 {data.partner.name} 
                             </h5>
                        </td>
                    </tr>
                    <tr>
                        <td
                        style={{
                            
                             paddingLeft:10,
                            fontWeight:'bold',
                        }} 
                    >Sender</td>
                    <td
                    style={{

                             
                        }}
                    >{data.sender_name}</td>    
                     <td
                        style={{
                            
                             paddingLeft:10,
                             fontWeight:'bold',
                        }} 
                    >Recipient</td>
                    <td
                    style={{
                            
                             
                        }}
                    >{data.receiver_name}</td>  
                    </tr>
                    <tr>
                        <td
                        style={{
                            
                              paddingLeft:10,
                             fontWeight:'bold',
                        }} 
                    >Phone</td>
                    <td
                    style={{
                            
                            
                        }}
                    >{data.sender_phone}</td>    
                        <td
                        style={{
                            
                              paddingLeft:10,
                             fontWeight:'bold',
                        }} 
                    >Phone</td>
                    <td
                    style={{
                            
                            
                        }}
                    >{data.receiver_phone}</td>    
                    </tr>

                    <tr>
                        <td colSpan={2}
                        style={{ fontWeight:'bold',  paddingLeft:10,paddingTop:10,}}
                        >
                            Destination


                        </td>    

                          <td colSpan={2}
                        style={{ fontWeight:'bold',  paddingLeft:10,paddingTop:10,}}
                        >
                            Price

                        </td>    
                    </tr>   

                    <tr>
                        <td
                        colSpan={2}
                        style={{
                             paddingLeft:10,
                        }}
                        >
                            <p>{data.receiver_address}<br/>
                            {data.receiver_city.name}, {data.receiver_state.name}, {data.receiver_country.name}</p>


                            <p>Box Size</p>
                            {data.box_size}

                        </td>
                        <td
                        colSpan={4}
                        style={{
                             paddingLeft:10,
                        }}
                        >
                            <p>Based price : 
                                RM {data.harga_dasar + data.partner_markup}
                                <br/>
                                Service Charge : 
                                RM {data.service_charge}
                                <br/>
                                Additional :  
                                RM {data.tambahan}
                                <br/>
                                Total :  
                                <span style={{fontWeight: 'bold'}}>RM {data.harga_dasar + data.partner_markup + data.service_charge + data.tambahan}</span>
                            </p>
                           
                            

                        </td>
                    </tr> 
                   
                   <tr>
                        <td
                        colSpan={4}
                        style={{
                                padding:10,
                                paddingTop:20,
                                textAlign:'center',
                            }}
                        >
                            Thank you for using our service
                            </td>
                   </tr>

                </thead>  
            </table>
            )
            }
        </div>
     );
}

export default OrderPrint;