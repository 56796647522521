import React from 'react'

import {useState, useEffect} from 'react'
import Header from '../../components/Header'

import { useNavigate, useParams } from 'react-router';
import { Button, Input, Uploader} from 'rsuite';
import CameraRetroIcon from '@rsuite/icons/legacy/CameraRetro';


import Config from '../../config/Config';
import Api from '../../services/Api';

import Loading from 'react-loading';
import { ToastContainer, toast } from "react-toastify";

function Setting() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [data, setData]: any = useState({});
    const [logoUpload, setLogoUpload] : any = useState('');

    useEffect(() => {
        
        const getPartnerData = () => {
            const token:any = localStorage.getItem('token');

            Api.get('/data/partner',token).then((response) => {
                console.log(response);
                setData(response);
                setLoading(false);
            });
        }

        getPartnerData();
    },[]);

    const saveAccount = () => {

        setLoading(true);

        const token:any = localStorage.getItem('token');
        Api.post('/data/partner',{
            mark_up:data.mark_up,
            logo:data.logo
        },token).then((response: any) => {
            console.log(response);
            toast("Data berhasil disimpan", {
                type: "success",
            });

            setLoading(false);
        });
    }

    const uploadLogo = () => {

        setUploading(true);

        // upload using form data
        const token:any = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('logo', logoUpload);
        Api.post('/data/partner',formData,token).then((response: any) => {
            console.log(response);
            toast("Data berhasil disimpan", {
                type: "success",
            });

            setUploading(false);
        });
    }

    return ( 
        <div className='min-h-screen bg-slate-100'>

            <Header />

            <div className='px-16 pb-2 pt-16'>
                <h4 className='font-semibold my-4'>Account</h4>
                
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-row justify-between w-1/3  p-4 bg-gray-50 rounded-lg min-h-64'>
                     
                            <div className='w-1/2'>
                                <div className='w-full'>
                                    <p className='my-2'>Upload logo</p>
                                    </div>
                                <div className='w-full'>
                                    <Uploader

                                        multiple={false}
                                        fileListVisible={false}
                                
                                        action={Config.api_url + '/data/partner/upload'}
                                        headers={{
                                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                                        }}

                                        onSuccess={(response: any, file: any) => {

                                            console.log(response);
                                            toast("Image uploaded successfull", {
                                                type: "success",
                                            });

                                            setData({
                                                ...data,
                                                logo: response.url
                                            })
                                        }
                                        }
                                        >
                                        <Button appearance='ghost' color='blue'
                                        >
                                            <CameraRetroIcon 
                                                width={20}
                                                height={20}
                                            />
                                        </Button>

                                        </Uploader>
                                </div>
                            </div>

                            <div className='w-1/2'>
                                <div className='w-full h-40 bg-gray-100'>
                                        <img src={data.logo} className='w-full h-full object-contain' />
                                </div>
                            </div>

                    </div>
                <div className='flex flex-row justify-between w-1/3  p-4 bg-gray-50 rounded-lg min-h-64'>

                        <form className='w-full' onSubmit={(e) => {
                            e.preventDefault();
                             saveAccount();

                        }} >
                        <div className='w-full'>
                            <p className='my-1'>Username</p>
                            <Input placeholder="Username"
                          
                            value={data.name} disabled/>
                            <p className='my-1'>Name</p>
                            
                            <p className='my-1'>Mark Up to branch</p>
                            <Input
                            onChange={(e: any) => {

                                setData({
                                    ...data,
                                    mark_up: e
                                })

                            }}
                           type="number" value={data.mark_up}/>

                            
                           

                            <div className='flex flex-row justify-end mt-4'>
                                {loading ? (
                                    <Loading type='spin' color='#000' height={20} width={20} />
                                ) : (
                                    <Button appearance='primary' color='green' type='submit'>Save</Button>
                                )  }
                            </div>
                        </div>
                        </form>

                </div>
                </div>

            </div>

            <ToastContainer />

        </div>
     );
}

export default Setting;