import React from 'react'
import { useEffect, useState } from 'react';
import Header from '../../components/Header'

import { Input, DatePicker, SelectPicker, Button } from 'rsuite';
import Loading from 'react-loading';

import Config from '../../config/Config';
import Api from '../../services/Api';
import ReportExcel from './ReportExcel';

function Report() {

    // today yyyy-mm-dd
    const today = new Date().toISOString().slice(0,10);

    // start of this month yyyy-mm-dd
    const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0,10);

    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate]: any = useState(startOfMonth);
    const [toDate, setToDate]: any = useState(today);
    const [statusess, setStatusess]: any = useState([
        {label:'All',value:'all'},
        {label:'Order Created',value:'ORDER CREATED'},
        {label:'Pickup',value:'PICKUP'},
        {label:'JPI Checking',value:'JPI CHECKING'},
        {label:'Received JPI',value:'RECEIVED JPI'},
        {label:'On Shipping',value:'ON SHIPPING'},
        {label:'Unloading Office HQ',value:'UNLOADING OFFICE HQ'},
        {label:'Shipping to Branch',value:'ON SHIPPING TO BRANCH'},
        {label:'Unloading Office Branch',value:'UNLOADING OFFICE BRANCH'},
        {label:'Delivered',value:'DELIVERED'},
        {label:'Canceled',value:'CANCEL'},
    ]);
    
    const [status, setStatus] = useState('all');
    const [data, setData]: any = useState([]);
    const [dataExport, setDataExport]: any = useState([{}]);

    useEffect(() => {
        const token:any = localStorage.getItem('token');
        Api.get('/data/report?status='+status+'&start_date='+fromDate+'&end_date='+toDate,token).then((response) => {
            console.log(response);
            setLoading(false);
            setData(response);

            let dataExport:any = [];
            response.map((item: any) => {

                dataExport.push({
                        no_resi: item.no_resi,
                        sender_name: item.sender_name,
                        receiver_name: item.receiver_name,
                        receiver_city: item.receiver_city.name,
                        status: item.status
                })   
            })

            setDataExport(dataExport);

        });
    },[fromDate,toDate,status]);

    const exportExcel = () => {
        
        return (
            <ReportExcel
            data={data}
            />
        )
    }

    return ( 
        <div className='min-h-screen bg-slate-100'>
            <Header />
            <div className='px-16 pb-8 pt-16'>
                
                <div className='flex flex-row justify-between items-center'>
                    <h5 className='my-4'>Report</h5>
                    <div>
                        <ReportExcel excelData={dataExport}
                            fileName='report'
                        />
                    </div>    
                </div>

                <div className='flex flex-row gap-2'>
                    <div className="w-1/3 bg-white rounded-lg shadow-sm p-8">
                        <p className='my-1'>From date</p>
                         <Input type="date"
                        onChange={(e:any) => {
                            setFromDate(e);
                        }
                        }
                        value={fromDate}
                        />
                        <p className='my-1'>To date</p>
                      
                        <Input type="date"
                        onChange={(e:any) => {
                            setToDate(e);
                        }
                        }
                        value={toDate}
                        />
                        <p className='my-1'>Status</p>
              
                     
                        <div className='w-full'>
                            <SelectPicker 
                            data={statusess}
                            value={status}
                            className='w-full'
                            onChange={(e: any) => {
                                setStatus(e);
                            }}
                            />
                        </div>
                    </div>
                    <div className="w-3/4 bg-white rounded-lg shadow-sm p-8">
                            <table className='w-full'>
                                <thead>
                                    <tr
                                    className='bg-gray-50 font-semibold'
                                    >
                                        <td className='p-2'>No</td>
                                        <td className='p-2'>Receipt number</td>
                                        <td className='p-2'>Sender name</td>
                                        <td className='p-2'>Recipient name</td>
                                        <td className='p-2'>Destination</td>
                                        <td className='p-2'>Status</td>
                                    </tr>   
                                </thead>
                                <tbody
                                className='[&>*:nth-child(even)]:bg-sky-50'
                                >
                                    {loading ? (
                                        <tr>
                                            <td colSpan={6} className='text-center'>
                                                <Loading type='spin' color='#000'/>
                                            </td>
                                        </tr>
                                    ) : (
                                        data.map((item: any, index: number) => {

                                            return (
                                                <tr>
                                                    <td  style={{width:10}} className='p-2'>{index + 1}</td>
                                                    <td className='p-2'>{item.no_resi}</td>
                                                    <td className='p-2'>{item.sender_name}</td>
                                                    <td className='p-2'>{item.receiver_name}</td>
                                                    <td className='p-2'>{item.receiver_city.name}</td>
                                                    <td className='p-2'>{item.status}</td>
                                                </tr>
                                            )
                                        })
                                    )}
                                </tbody>
                            </table>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default Report;